import { OnDestroy, Component } from '@angular/core';
import { Subject } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mytrove',
  template: '',
  styleUrls: []
})
export class TroveComponent implements OnDestroy {

    ngUnsubscribe: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    showErrors(form: FormGroup, formErrors: object, validationMessages: object, ignoreDirty: boolean) {
      if (!form) { return; }

      for (const field of Object.keys(formErrors)) {
        // clear previous error message (if any)
        formErrors[field] = '';
        const control = form.get(field);

        if (control && (control.dirty || ignoreDirty) && !control.valid) {
          const messages = validationMessages[field];
          for (const key of Object.keys(control.errors)) {
            formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }

    showErrorMessage(dialog: MatDialog, message: string) {
      this.showMessage(dialog, 'PROBLEM', message);
    }

    showMessage(dialog: MatDialog, title: string, message: string): void {
      const dialogRef = dialog.open(MessageDialogComponent, {
        width: '500px',
        data: { title: title, message: message}
      });
    }

    showOptionsDialog(dialog: MatDialog, title: string, message: string, button1: string, button2: string, onClose?: (buttonPressed: string) => void) {
      const dialogRef = dialog.open(MessageDialogComponent, {
        width: '320px',
        data: {
          title: title,
          message: message,
          button1: button1,
          button2: button2,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (onClose) {
          onClose(result);
        }
      });
    }

}
