import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  public log(message: string, ...optionalParams: any[]) {
    if (environment.logging) {
      console.log(message, optionalParams);
    }
  }

  public warn(message: string) {
    if (environment.logging) {
      console.warn(message);
    }
  }

  public error(message: string) {
    console.error(message);
  }

}
