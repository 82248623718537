import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';

import * as Raven from 'raven-js';
import { HomeComponent } from './content/home/home.component';
import { NotFoundComponent } from './content/not-found/not-found.component';
import { AboutComponent } from './content/about/about.component';
import { PrivacyComponent } from './content/privacy/privacy.component';
import { TermsComponent } from './content/terms/terms.component';
import { NewsletterComponent } from './content/newsletter/newsletter.component';
import { LearnMoreComponent } from './content/learn-more/learn-more.component';
import { DisclosureComponent } from './content/disclosure/disclosure.component';
import { ContactModule } from './content/contact/contact.module';
import { SharedModule } from './shared/shared.module';
import { NewsletterSubscribeComponent } from './content/newsletter-subscribe/newsletter-subscribe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthenticationService } from './services/authentication.service';
import { AnalyticsService } from './services/analytics.service';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { ExternalLinksComponent } from './content/external-links/external-links.component';

Raven
.config('https://8e4c3c458a164945823d048f50013f62@sentry.io/222554')
.install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    AboutComponent,
    PrivacyComponent,
    TermsComponent,
    NewsletterComponent,
    LearnMoreComponent,
    DisclosureComponent,
    NewsletterSubscribeComponent,
    ExternalLinksComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ContactModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireFunctionsModule,
    AppRoutingModule,
  ],
  providers: [
    AuthenticationService,
    AnalyticsService,
    { provide: ErrorHandler, useClass: RavenErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
